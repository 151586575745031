.app__skills {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white-color); // Ensure the entire section has a white background
}

.app__skills-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0; // Adds padding around the whole container for better spacing
}

.app__skills-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); // Flexible grid for skills
  gap: 1rem;
  justify-content: center;
  width: 100%;
}

.app__skills-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fef4f5;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      width: 70%;
      height: auto;
    }

    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    }
  }

  p {
    font-size: 1rem;
    margin-top: 10px;
    color: var(--gray-color);
  }
}

.app__skills-exp {
  width: 100%;
  margin-top: 3rem; // Adds space between skills and experiences
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app__skills-exp-item {
  width: 90%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.app__skills-exp-year {
  font-weight: bold;
  color: var(--secondary-color);
}

.app__skills-exp-works {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skills-tooltip {
  max-width: 300px;
  background-color: var(--white-color);
  padding: 1rem;
  border-radius: 5px;
  color: var(--gray-color);
  text-align: center;
  line-height: 1.5;
}
