.header {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the title container */
    background-color: #f5f5f5;
    padding: 0; /* Remove padding */
    position: relative;
    overflow: hidden; /* Ensure no overflow */
}

.header-left {
    position: relative;
    flex: 1;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the header area */
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.title-container {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
}

.title {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
}




.subtitle {
    font-size: 1rem;
    margin-top: 10px;
}

.intro-image {
    width: 200px;
    height: auto;
    margin: 1rem;
    border-radius: 8px;
    z-index: 2;
}

.text {
    margin: 20px;
    font-size: 1rem;

    span{
        color: purple;
    }
}

.section-title {
    font-size: 2rem;
    color: #6a1b9a; /* Shade of purple */
    margin: 20px 0;
}

.snowtech_image {
    display: block;
    margin: 20px auto;
    max-width: 40%;
    max-height: 40%;
    position: relative;
}

.image {
    display: block;
    margin: 20px auto;
    max-width: 60%;
    max-height: 60%;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.image:hover {
    transform: scale(1.1);
}

.project-section {
    padding: 20px;
}

.section-subtitle {
    font-size: 1.5rem;
    color: #8e5ba0; /* Light purple shade */
    margin: 20px 0 10px;
}

.section-description {
    font-size: 1.2rem;
    color: black; /* Black for body text */
    margin-bottom: 20px;
}

.section-list {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
}

.section-list li {
    margin-bottom: 10px;
}

.components-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.component {
    flex: 1 1 250px;
    text-align: center;
}

.component img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.component img:hover {
    transform: scale(1.1);
}

.component p {
    margin: 10px 0;
    font-size: 0.9em;
}

.video-container {
    margin: 20px 0;
    text-align: center;
}

.video-container video {
    width: 100%;
    max-width: 600px;
    height: auto;
}

.note {
    margin: 20px;
    font-size: 0.9rem;
    color: #333;
}

.project-footer {
    text-align: center;
    padding: 20px;
    background: #f1f1f1;
    color: #4B0082; /* Indigo */
}
.full-screen-image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.full-screen-image {
    max-width: 90%;
    max-height: 90%;
    cursor: pointer;
}
